import React from 'react';
import * as styles from './Columns.module.css'
import { DatoDocumentType } from '../../PageTemplates/Category/Three/query';
import DocumentListItem from '../Item/Item';

interface DocumentListColumnsProps {
    columns: {[a: string]: DatoDocumentType[]};
    type?: 'Notes' | 'Worksheets' | 'Cheat Sheets';
}

const sortingMap = {
    'Question Paper': 6,
    'Mark Scheme': 5,
    'Written Mark Scheme': 4,
}
const columnSorting = (a: string, b: string) => {
    const aMapped = sortingMap[a] || 0;
    const bMapped = sortingMap[b] || 0;
    
    if (aMapped === bMapped) {
        return a < b ? 1 : -1;
    }
    return aMapped < bMapped ? 1 : -1;
}

const DocumentListColumns = ({columns, type}: DocumentListColumnsProps) => {
    return (
        <div className={`${styles.container}`}>
            {
                Object.keys(columns).sort(columnSorting).map((key, index) => {
                    return (
                        <div className={styles.column}>
                            <span className={styles.header} key={`${key}-header`}>
                                {key}
                            </span>
                            <ul className={styles.itemList}>
                            {
                                columns[key].map((i, itemIndex) => {
                                    return (
                                        <DocumentListItem document={i} type={type} className={styles.item} style={{gridColumnStart: index + 1, gridColumnEnd: index + 2, gridRowStart: 2 + itemIndex}} key={`${key}-${i.id}`}/>
                                    )
                                })
                            }
                            </ul>
                        </div>

                    )
                })
            }

        </div>

    )
};

export default DocumentListColumns;
