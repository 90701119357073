import React from 'react';

interface BreadcrumbsProps {
    crumbs: {
        url: string;
        label: string;
    }[];
    className?: string;
}

const Breadcrumbs = ({crumbs, className}: BreadcrumbsProps) => {
    return (
        <div className={className}>
            {
                crumbs.map((crumb, index) => {
                    return (
                        <>
                            <a className="page-breadcrums" href={crumb.url}>
                                {crumb.label}
                            </a>
                            {
                                index < crumbs.length - 1 && (
                                    ' > '
                                )
                            }
                        </>
                    )
                })
            }
        </div>
    )
};

export default Breadcrumbs;
