import React, { useMemo } from 'react';
import DocumentListItem from './Item/Item';
import { DatoDocumentType } from '../PageTemplates/Category/Three/query';
import DocumentListColumns from './Columns/Columns';

const NoDataImage = '/images/no-data.svg';


interface DocumentsListProps {
    documents: DatoDocumentType[];
    showNoData?: boolean;
    type?: 'Notes' | 'Worksheets' | 'Cheat Sheets'
}

const DocumentsList = ({ documents, showNoData, type }: DocumentsListProps) => {

    const freeDocs = documents.filter((doc) => !doc.hasPassword || doc.freeDocument);
    const paidDocs = documents.filter((doc) => !doc.freeDocument && doc.hasPassword);

    const renderNoData = useMemo(() => { return showNoData && !documents.length }, [documents.length, showNoData])
    const renderFreeDocs = useMemo(() => { return documents && documents.length > 0 }, [documents])
    const renderPaidDocs = useMemo(() => { return !!paidDocs }, [paidDocs])

    const columnisedFreeDocs: {[a: string]: DatoDocumentType[]} | undefined = useMemo(() => {
        // const hasEnoughDocuments = freeDocs.length >= 6;
        const areAllPastPapers = freeDocs.every((d) => d.docType?.name === "Past Papers");
        const areAllTypedPastPapers = freeDocs.every((d) => !!d.docPastPaperType?.name);

        if (areAllPastPapers && areAllTypedPastPapers) {
            const result: {[a: string]: DatoDocumentType[]} = {}
            freeDocs.forEach((a) => {
                result[a.docPastPaperType?.name] = result[a.docPastPaperType?.name] || [];
                result[a.docPastPaperType?.name].push(a);
            })
            const hasEnoughColumns = Object.keys(result).length >= 2;
            if (hasEnoughColumns) {
                return result;
            }
        }
        return undefined;
    }, [freeDocs])

    return (
        <div>
            {renderNoData && (
                <div className="no-data">
                    <img src={NoDataImage} className="no-data-image" alt="Category optiom" />
                    <p className="no-data-text">
                    {
                        `No documents here yet but we're working really hard to provide them soon!
                    
                        You can sign up to our newsletter in the meantime to get informed about new releases.`
                    }
                    </p>
                </div>
            )}
            {renderFreeDocs && (
                columnisedFreeDocs ? (
                    <DocumentListColumns columns={columnisedFreeDocs} /> 
                ) : (
                    <ul className="category-options">
                        {freeDocs.map((i) => (
                            <DocumentListItem document={i} type={type} />
                        ))}
                    </ul>
                )
            )}
            {renderPaidDocs && (
                <div className="documents">
                    {paidDocs.map((i) => (
                        <DocumentListItem document={i} type={type}/>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DocumentsList;
