/* eslint-disable no-useless-escape */
import React from 'react';
import { DatoDocumentType } from '@src/components/Document/PageTemplates/Category/Three/query';
import { addToCart } from '@src/store/cart';
import { RootStoreState } from '@src/store';
import { UserData } from '@src/types/User/User';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

interface DocumentCoverPageListItemProps {
    document: DatoDocumentType;
}


const LockIcon = '/icons/lock.svg';
const LockSubIcon = '/icons/sub-lock.svg';
const UnlockIcon = '/icons/open-lock.svg';
const UnlockSubIcon = '/icons/open-sub-lock.svg';
const YoutubeLogo = '/images/youtube_logo.png';

const slugify = (text) =>
    text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text

const DocumentCoverPageListItem = ({document}: DocumentCoverPageListItemProps) => {
    const miniCart = useSelector<RootStoreState>((state) => new Set(state.cart.miniCart)) as Set<string>;
    const ownedProducts = useSelector<RootStoreState>((state) => new Set(state.cart.ownedProducts)) as Set<string>;
    const user = useSelector<RootStoreState>((state) => state.user.data) as UserData;

    const dispatch = useDispatch();

    const isSubscribed = user?.subscribed ?? false;

    const renderCoverImage = (module) => {
        let url = '';
        switch (module) {
            case 'a-level':
                url = '/a-level.png';
                break;
            case 'ks3-maths-years-7-8-and-9':
                url = '/years7-9.png';
                break;
            case '11-plus':
                url = '/11-plus.png';
                break;
            case 'ap-calculus':
                url = '/ap-calculus.png';
                break;
            case 'ap-statistics':
                url = '/ap-statistics.png';
                break;
            case 'gcse-igcse-o-level':
                url = '/gcse.png';
                break;
            case 'ib':
                url = '/ib.png';
                break;
            case 'university':
                url = '/university.png';
                break;
        }
        return <img src={url} alt="my maths cloud cover" />;
    };

    function mainButtonClick(id) {
        dispatch(addToCart(id, user));
    }

    let documentState = 'Buy';

    if (miniCart.has(document.id)) {
        documentState = 'in cart';
    }

    if (ownedProducts.has(document.id)) {
        documentState = 'Purchased';
    }

    if (document.isSubscription && isSubscribed && !ownedProducts.has(document.id))
        documentState = 'Open';

    const handleDocumentClick = () => {
        switch (documentState) {
            case 'Buy':
                mainButtonClick(document.id);
                break;
            case 'Purchased':
                navigate('/profile');
                break;
            case 'Open':
                //navigate to open page
                navigate(`/embeded?id=${document.id}`);
                break;
            default:
                navigate('/cart');
                break;
        }
    };

    const module = document.module?.slug;
    return (
        <div key={document.id} className="document-wrapper">
            <div
                className="document-image text-center"
                onClick={() =>
                (window.location.href =
                    document.isSubscription && isSubscribed
                        ? document?.videoGuide?.url
                            ? `/embeded?id=${document.id}&videoGuide=${encodeURI(document?.videoGuide?.url)}`
                            : `/embeded?id=${document.id}`
                        : `/modules/${module}/${document.documentCategory
                            .map((c) => c.slug)
                            .join('/')}/${encodeURIComponent(slugify(document.name))}`)
                }
            >
                {renderCoverImage(module)}

                <div className="document-name category-option-link button-text">
                    {document.displayName || document.name}
                </div>
            </div>
            <span className="category-option-info srow y-centered action-bar x-space-between">
                {document.freeSample && (
                    <>
                        <span className="badge free-sample">FREE SAMPLE</span>PDF
                    </>
                )}
                {!document.freeSample && !document.freeDocument && document.hasPassword && (
                    <>
                        <>
                            <span
                                className={`badge premium ${document.isSubscription && isSubscribed ? 'strike-through' : ''
                                    }`}
                            >
                                £{document.price}
                            </span>
                            {documentState === 'Open' ? (
                                <img
                                    src={UnlockSubIcon}
                                    className="lock-icon"
                                    alt="Unlock sub icon"
                                    onClick={handleDocumentClick}
                                />
                            ) : (
                                <>
                                    <button
                                        className={`button-b badge free-sample ${document.isSubscription && isSubscribed ? 'green-button' : ''
                                            }`}
                                        onClick={handleDocumentClick}
                                    >
                                        {documentState}
                                    </button>
                                    {documentState === 'Purchased' ? (
                                        <img
                                            src={UnlockIcon}
                                            className="lock-icon"
                                            alt="Unlock sub icon"
                                            onClick={handleDocumentClick}
                                        />
                                    ) : document?.isSubscription ? (
                                        <img
                                            src={LockSubIcon}
                                            className="lock-icon"
                                            alt="Unlock sub icon"
                                            onClick={() => navigate('/subscriptions')}
                                        />
                                    ) : (
                                        <img
                                            src={LockIcon}
                                            className="lock-icon"
                                            alt="Unlock sub icon"
                                            onClick={handleDocumentClick}
                                        />
                                    )}
                                </>
                            )}
                        </>
                        <>
                            {document.previewVideo && (
                                <div className="flex flex-center">
                                    <img src={YoutubeLogo} style={{ width: '2rem', marginRight: '0.5rem' }} alt="Youtube logo" />
                                    <a href={document.previewVideo} target="_blank" style={{ fontSize: '1rem' }} rel="noreferrer">
                                        Preview Video
                                    </a>
                                </div>
                            )}
                        </>
                    </>
                )}
            </span>
        </div>
    );
};

export default DocumentCoverPageListItem;
