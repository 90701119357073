import { DatoDocumentType } from '@src/components/Document/PageTemplates/Category/Three/query';
import { RootStoreState } from '@src/store';
import { UserData } from '@src/types/User/User';
import { formatCurrency } from '@src/utils/price';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DocumentIcon from '../../../../../assets/icons/pdf2.svg';
import WordDocumentIcon from '../../../../../assets/icons/word-icon.png';
import PowerPointIcon from '../../../../../assets/icons/pptx-icon-no-bg.png';
import LockIcon from '../../../../../assets/icons/lock.svg';
import LockSubIcon from '../../../../../assets/icons/sub-lock.svg';
import UnlockSubIcon from '../../../../../assets/icons/open-sub-lock.svg';
import YoutubeLogo from '../../../../../assets/images/youtube_logo.png';

interface DocumentLinkListItemProps {
    document: DatoDocumentType;
    type?: 'Notes' | 'Worksheets' | 'Cheat Sheets';
    style?: React.CSSProperties;
    className?: string;
}

const isBrowser = typeof window !== 'undefined';

const DocumentLinkListItem = ({document, type, style, className}: DocumentLinkListItemProps) => {

    const user = useSelector<RootStoreState>((state) => state.user.data) as UserData;
    const [price, setPrice] = useState<number>(undefined);

    useEffect(() => {
        if (type) {
            if (type.includes('Notes')) {
                setPrice(20);
            }
            if (type.includes('Worksheets')) {
                setPrice(25);
            }
            if (type.includes('Cheat Sheets')) {
                setPrice(15);
            }
        }
    }, [type]);

    const isSubscribed = user?.subscribed ?? false;

    const fileUrl = document.fileUrl.includes('https://mymathscloud.ams3.digitaloceanspaces.com/')
        ? unescape(document.fileUrl.replace('https://mymathscloud.ams3.digitaloceanspaces.com/', ''))
        : document.fileUrl;


    const handleDocumentClick = () => {
        if (isBrowser && document?.hasPassword) localStorage.setItem('open-modal-on-load', true as unknown as string);
        if (document?.isSubscription) {
            if (isSubscribed) window.location.href = `/embeded?id=${document.id}`;
            else alert('You must be subscribed to see this document.');
        } else {
            window.location.href = `${process.env.GATSBY_BACKEND_URL}/api/download/modules/${fileUrl}?id=${document.id}`;
        }
    };

    const href = document.externalUrl ? document.externalUrl :
        document?.isSubscription && isSubscribed
            ? `/embeded?id=${document.id}`
            : document.isSubscription
                ? ''
                : `${process.env.GATSBY_BACKEND_URL}/api/download/modules/${fileUrl}?id=${document.id}`;

    const typeOfDoc = fileUrl.endsWith('.docx')
        ? 'word'
        : fileUrl.endsWith('.pptx')
            ? 'pptx'
            : 'pdf';
    const documentMap = {
        word: {
            icon: WordDocumentIcon,
            label: 'Word',
        },
        pptx: {
            icon: PowerPointIcon,
            label: 'PowerPoint',
        },
        pdf: {
            icon: DocumentIcon,
            label: 'PDF',
        },
    };

    return (
        <li className={`category-option ${className}`} key={document.id} style={style}>
            <img
                src={documentMap[typeOfDoc].icon}
                className="category-option-image"
                alt="Category option"
            />
            <div className="category-option-text">
                <a
                    href={href}
                    onClick={handleDocumentClick}
                    className="category-option-link button-text mb-0 cursor-pointer"
                >
                    {document.displayName || document.name}
                </a>
                <span className="category-option-info lh-1.5">
                    {document.freeSample && <span className="badge free-sample">FREE SAMPLE</span>}
                    {!document.freeSample && !document.freeDocument && document.hasPassword && (
                        <span className="badge premium">
                            {document.price || formatCurrency.format(price)}
                        </span>
                    )}
                    {documentMap[typeOfDoc].label}
                    {document.videoSolution && (
                        <span className="ml-0.5">
                            <a href={document.videoSolution} target="_blank" style={{ fontSize: '1rem' }} rel="noreferrer">
                                Video solution
                            </a>
                            <img src={YoutubeLogo} style={{ width: '2rem', marginLeft: '0.5rem' }} alt="Youtube Logo" />
                        </span>
                    )}
                </span>
            </div>
            {document.isSubscription && isSubscribed ? (
                <img src={UnlockSubIcon} className="lock-icon" alt="Unlock" />
            ) : document.isSubscription ? (
                <img
                    src={LockSubIcon}
                    className="lock-icon"
                    alt="Locked with sub content"
                    onClick={() => navigate('/subscriptions')}
                />
            ) : document.hasPassword ? (
                <img src={LockIcon} className="lock-icon" alt="Locked" />
            ) : null}
        </li>
    );
};

export default DocumentLinkListItem;
