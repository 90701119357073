import { navigate } from 'gatsby';
import React from 'react';
import { Subscriptionforumbutton } from '../query';

interface ModuleSubCategoryDocumentsPageActionSectionProps {
    formButtonData?: Subscriptionforumbutton;
}

const ModuleSubCategoryDocumentsPageActionSection = ({formButtonData}: ModuleSubCategoryDocumentsPageActionSectionProps) => {

    const showSubscriptionButton = formButtonData?.displaysubscription ?? false;
    const showForumButton = formButtonData?.displayforum ?? false;
    const subscriptionBackgroundColor =
      formButtonData?.subbackgroundcolor?.hex ?? '#23D4FF';
    const subscriptionTextColor = formButtonData?.subtextcolor?.hex ?? '#000000';
    const forumBackgroundColor =
      formButtonData?.forumbackgroundcolor?.hex ?? '#FD99C4';
    const forumTextColor = formButtonData?.forumtextcolor?.hex ?? '#000000';

    return (
        <div className="action-container">
          {showSubscriptionButton && (
            <button
              onClick={() => navigate('/subscriptions')}
              className="w-nav-brand sign-in-badge forum-sub-button"
              style={{
                backgroundColor: subscriptionBackgroundColor,
                color: subscriptionTextColor,
              }}
            >
              <span className="2">Join Now</span>
            </button>
          )}
          {showForumButton && (
            <button
              onClick={() => navigate('/forum')}
              className="w-nav-brand sign-in-badge forum-sub-button"
              style={{
                backgroundColor: forumBackgroundColor,
                color: forumTextColor,
              }}
            >
              <span className="2">Forum</span>
            </button>
          )}
        </div>
      );

};

export default ModuleSubCategoryDocumentsPageActionSection;
