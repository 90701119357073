import React, { useMemo } from 'react';
import { DatoDocumentType } from '../../PageTemplates/Category/Three/query';
import DocumentCoverPageListItem from './CoverPage/CoverPage';
import DocumentLinkListItem from './Link/Link';

interface DocumentListItemProps {
    document: DatoDocumentType;
    type?: 'Notes' | 'Worksheets' | 'Cheat Sheets'
    className?: string;
    style?: React.CSSProperties;
}

const DocumentListItem = ({ document, type, className, style }: DocumentListItemProps) => {

    const isFree = useMemo(() => {
        return document.freeDocument || document.freeSample || (document.hasPassword && document.freeDocument);
    }, [document.freeDocument, document.freeSample, document.hasPassword])


    return (
        isFree ? (
            <DocumentLinkListItem document={document} type={type} className={className} style={style} />
        ) : (
            <DocumentCoverPageListItem document={document} />
        )
    )
};

export default DocumentListItem;
